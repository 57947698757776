import { loginStore } from "@/stores/LoginStore";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/reset-password",
        name: "PasswordReset",
        meta: {
            requiresAuth: false,
            hideHeader: true,
        },

        component: () =>
            import("@/views/LogIn/PasswordResetView.vue"),
    },
    {
        path: "/login",
        name: "LogIn",
        meta: {
            requiresAuth: false,
            hideHeader: true,
        },

        component: () =>
            import("@/views/LogIn/LogInView.vue"),
    },
    {
        path: "/register",
        name: "Register",
        meta: {
            requiresAuth: false,
            hideHeader: true,
        },

        component: () =>
            import("@/views/Leads/LeadView.vue"),
    },
    {
        path: "/simple-booking/:id",
        name: "SimpleBooking",
        meta: {
            requiresAuth: false,
            hideHeader: true,
        },

        component: () =>
            import("@/views/Bookings/SimpleBookingView.vue"),
    },
    {
        path: "/wholesaler-booking/:id",
        name: "WholesalerBooking",
        meta: {
            requiresAuth: false,
            hideHeader: true,
        },

        component: () =>
            import("@/views/Bookings/WholesalerBookingView.vue"),
    },
    {
        path: "/home",
        name: "Home",

        component: () =>
            import("@/views/LogIn/HomeView.vue"),
    },
    {
        path: "/bookings",
        name: "GetAllBookings",

        component: () =>
            import(/* webpackChunkName: "bookings" */ "@/views/Bookings/GetAllBookingsView.vue"),
    },
    {
        path: "/new-booking",
        name: "NewBooking",

        component: () =>
            import(/* webpackChunkName: "bookings" */ "@/views/Bookings/EditOrCreateBookingView.vue"),
    },
    {
        path: "/bookings/:id",
        name: "EditBooking",

        component: () =>
            import(/* webpackChunkName: "bookings" */ "@/views/Bookings/EditOrCreateBookingView.vue"),
    },
    {
        path: "/voucher/:id/:reference",
        name: "ViewVoucher",

        component: () =>
            import(/* webpackChunkName: "bookings" */ "@/views/Bookings/VoucherView.vue"),
    },
    {
        path: "/activities",
        name: "ListActivities",

        component: () =>
            import(/* webpackChunkName: "bookings" */ "@/views/Activities/ListActivitiesView.vue"),
    },
    {
        path: "/documentation",
        name: "GetDocumentation",

        component: () =>
            import(/* webpackChunkName: "documentation" */ "@/views/Documentation/DocumentationView.vue"),
    },
    {
        path: "/meeting-points",
        name: "MeetingPoints",

        component: () =>
            import(/* webpackChunkName: "documentation" */ "@/views/Documentation/MeetingPointsView.vue"),
    },
    {
        path: "/e-list",
        name: "GetAllElectronicLists",

        component: () =>
            import(/* webpackChunkName: "elists" */ "@/views/Lists/GetAllElectronicLists.vue"),
    },
    {
        path: "/e-list/:id",
        name: "ViewElectronicList",

        component: () =>
            import(/* webpackChunkName: "elists" */ "@/views/Lists/ViewElectronicList.vue"),
    },
    {
        path: "/personal-documentation",
        name: "PersonalDocumentation",

        component: () =>
            import(/* webpackChunkName: "documentation" */ "@/views/Documentation/PersonalDocumentationView.vue"),
    },
    {
        path: "/personal-history",
        name: "PersonalHistory",

        component: () =>
            import(/* webpackChunkName: "documentation" */ "@/views/Documentation/PersonalHistoryView.vue"),
    },
    {
        path: "/faqs",
        name: "ViewFaqs",

        component: () =>
            import(/* webpackChunkName: "documentation" */ "@/views/Faqs/FaqsView.vue"),
    },

];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const store = loginStore();
    if (!store.initialized) {
        store.initialize();
    }

    const authenticated = store.authenticated;
    const requiresAuth = (to.meta?.requiresAuth === false) ? false : true;

    // console.log("to", to);
    // console.log("requiresAuth", requiresAuth);
    // console.log("authenticated", authenticated);

    if (requiresAuth && !authenticated) {
        next({ name: "LogIn" });
        return;
    } else {
        next();
    }
});

export default router;
